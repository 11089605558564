import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Navbar, Nav, Dropdown, Form } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import layoutHelpers from './helpers'
import store from '../../store'
import '../../vendor/libs/react-bootstrap-typeahead/react-bootstrap-typeahead.scss'
import { getUserInfo, setDefaultWebsite } from '../../api/ExternalApi'
import { withTranslation } from 'react-i18next'

class LayoutNavbar extends Component {
  constructor(props) {
    super(props)
   
    this.isRTL = document.documentElement.getAttribute('dir') === 'rtl'
    var state = store.getState();
    var selectedWebsiteGuid = null;
    var websites = state.user.websites.map((r) => {
      var website = { guid: r.websiteGuid, label: r.websiteName, isDefault: r.isDefault, websiteName: r.websiteName };
      if (r.isDefault) {
        selectedWebsiteGuid = website.guid;
      }
      return website;
    });

    this.state = {
      redirect: null,
      selectedWebsiteGuid: selectedWebsiteGuid,
      websites: websites,
      isAuthenticated: false
    }
    //console.log('LayoutNavbar constructor', this.props.location.pathname , this.props.navigateToLink)
    if (this.props.navigateToLink && this.props.location.pathname == this.props.navigateToLink) {
      store.dispatch({ type: 'redirect', data: null })
    }
  }

  toggleSidenav(e) {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  logout = () => {
    localStorage.clear();
    store.dispatch({type: 'setUserInfo', data: {}})
    //store.dispatch({type: 'redirect', data: '/login'})
    //this.setState({redirect: '/login'})
  }

  onChangeWebsite = (ev) => {
    const websiteGuid = ev.target.value;
    setDefaultWebsite(websiteGuid).then(data => {
      if (data) {
        this.setState({ selectedWebsiteGuid: websiteGuid });
        getUserInfo().then(data => {
          store.dispatch({ type: 'setUserInfo', data: data });

          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
      }
    });
  }

  render() {
    //console.log(this.props);
    if (!this.props.isLoggedIn) {
      return <Redirect to={'/login'} />
    }
    //console.log("title type", typeof(this.props.title));

    return (
      <Navbar bg={this.props.navbarBg} expand="sm" className="layout-navbar align-items-center container-p-x" style={{ zIndex: 1001 }}>

        {/* Brand */}
        {/* <Navbar.Brand as={NavLink} to="/">React Starter</Navbar.Brand> */}

        {/* Sidenav toggle */}
        {this.props.sidenavToggle && (
          <Nav className="align-items-lg-center mr-auto mr-lg-4">
            <Nav.Item as="a" className="nav-item nav-link px-0 ml-2 ml-lg-0" href="#toggle" onClick={this.toggleSidenav}>
              <i className="ion ion-md-menu text-large align-middle"></i>
            </Nav.Item>
          </Nav>
        )} 

        {(this.props.title && typeof (this.props.title) == 'object')
          ? <div className="page-title" style={{ fontSize: '1.125rem', color: 'rgb(82, 79, 79)', fontWeight: 'bold', margin: 0 }}>{this.props.title.content}</div>
          : <div className="page-title" style={{ fontSize: '1.125rem', color: 'rgb(82, 79, 79)', fontWeight: 'bold', margin: 0 }}>{this.props.title}</div>
        }

        {/* Navbar toggle */}        
        <Navbar.Toggle />
        
        <Navbar.Collapse>
          <Nav className="align-items-lg-center ml-auto">
            {this.state.websites.length > 1 &&
              <Form.Control as="select" className='form-control-sm' value={this.state.selectedWebsiteGuid}
                onChange={(ev => { this.onChangeWebsite(ev); })}>
                {/* {this.state.websites.map(w => <option key={w.guid} value={w.guid}>{w.websiteName}</option>)} */}
                {this.state.websites.map((w, index) => <option key={`${w.guid}-${index}`} value={w.guid}>{w.websiteName}</option>)}
              </Form.Control >
            }

            <Dropdown as={Nav.Item} className="demo-navbar-user" alignRight={!this.isRTL}>
              <Dropdown.Toggle as={Nav.Link}>
                <span className="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                  <img src={`${process.env.PUBLIC_URL}/login-logo.png`} className="d-block ui-w-30 rounded-circle" alt="User" />
                  <span className="px-1 mr-lg-2 ml-2 ml-lg-0">{this.props.user.firstName} {this.props.user.lastName}</span>
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/my-profile" id="myProfileLink">
                  <i className="ion ion-ios-person text-lightest"></i> &nbsp; {this.props.t('common:navbar.myProfile')}
                </Dropdown.Item>
                {/* <Dropdown.Item hred="#"><i className="ion ion-ios-mail text-lightest"></i> &nbsp; Messages</Dropdown.Item> */}
                {/* <Dropdown.Item hred="#"><i className="ion ion-md-settings text-lightest"></i> &nbsp; Account settings</Dropdown.Item> */}
                <Dropdown.Divider />
                <Dropdown.Item hred="#" onClick={this.logout}><i className="ion ion-ios-log-out text-danger"></i> &nbsp; {this.props.t('common:navbar.logout')}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

LayoutNavbar.propTypes = {
  sidenavToggle: PropTypes.bool
}

LayoutNavbar.defaultProps = {
  sidenavToggle: true
}

export default withTranslation()(connect(store => ({
  navbarBg: store.theme.navbarBg,
  isLoggedIn: store.user?.token != null,
  user: store.user
}))(LayoutNavbar))

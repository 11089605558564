// @ts-nocheck
import moment, { Moment } from "moment";
import store from "../store";
import { toast } from "react-toastify";
import "../vendor/libs/react-toastify/react-toastify.scss";
import axios, { Method, ResponseType } from "axios";
import {
  GuidEmpty,
  showToastMessages,
  showToastSuccessAndErrorMessagesFromApiResponse,
} from "../utils";
import { BookingPriceInfo, LatLng } from "./types/common";

const environments = {
  live: {
    ExternalApiUrl: "https://external.limolink.net",
    CbsApiUrl: "https://api.limolink.net",
    GoldsainteApiUrl: "https://gs.limolink.net",
    CbsOldApiUrl: "https://account.chauffeurbookingsoftware.com",
    EntitiesApiUrl: "https://eu-entities-api.limolink.net",
  },
  staging: {
    ExternalApiUrl:
      "https://external-api-test.cbs.chauffeurbookingsoftware.com",
    CbsApiUrl: "https://api-test.limolink.net",
    GoldsainteApiUrl: "https://gs-test.limolink.net",
    CbsOldApiUrl: "https://ta.chauffeurbookingsoftware.com",
    EntitiesApiUrl: "https://test-entities-api.limolink.net",
  },
  local: {
    ExternalApiUrl: "https://localhost:44308",
    CbsApiUrl: "https://localhost:44331",
    GoldsainteApiUrl: "https://localhost:44313",
    CbsOldApiUrl: "http://localhost",
    EntitiesApiUrl: "https://localhost:7028",
  },
};

const environment = environments.live;

export const EntitiesApiUrl =
  process.env.REACT_APP_VERCEL_API_EntitiesApiUrl || environment.EntitiesApiUrl;
export const CbsOldApiUrl =
  process.env.REACT_APP_VERCEL_API_CbsOldApiUrl || environment.CbsOldApiUrl;
export const ExternalApiUrl =
  process.env.REACT_APP_VERCEL_API_ExternalApiUrl || environment.ExternalApiUrl;

export const CbsApiUrl = (useRegion: boolean = true) => {
  const state = store.getState();
  const regionApiUrl = state.user?.apis?.cbsApi?.[state.user?.preferredRegion];

  if (useRegion && state.user?.preferredRegion !== "main" && regionApiUrl) {
    return regionApiUrl;
  }

  return process.env.REACT_APP_VERCEL_API_CbsApiUrl || environment.CbsApiUrl;
};

export const GoldsainteApiUrl = process.env
  ?.REACT_APP_VERCEL_API_GoldsainteApiUrl
  ? process.env?.REACT_APP_VERCEL_API_GoldsainteApiUrl
  : environment.GoldsainteApiUrl;

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.error("Interceptor request error:", error);
    const { config: originalRequest, response } = error;

    if (!response) {
      return Promise.reject(error);
    }

    const shouldRetry =
      (response.status === 401 ||
        response.status === 301 ||
        originalRequest.method === "get") &&
      !originalRequest._retry;

    if (shouldRetry) {
      const userProfile = await refreshUserToken();
      if (userProfile) {
        await store.dispatch({ type: "setUserInfo", data: userProfile });
        originalRequest.headers[
          "Authorization"
        ] = `Bearer ${userProfile.jwtToken}`;
        originalRequest._retry = true;
        return axios(originalRequest);
      } else {
        console.error("Interceptor logout due to invalid token");
        await store.dispatch({ type: "setToken", data: null });
      }
    }

    return Promise.reject(error);
  }
);

function showError(message: string) {
  showToastMessages(message);
}

//#region User management API

export async function loginUser(
  email: string,
  password: string
): Promise<ApiResponse<LoginResult> | null> {
  const body = { email, password };
  const config = getPostOptions(body);

  try {
    const response = await axios.post(
      `${CbsOldApiUrl}/accountApi/weblogin`,
      body,
      config
    );
    const result = response.data;

    if (result.success) {
      return result as ApiResponse<LoginResult>;
    } else {
      showError("Invalid email or password. Please try again.");
      return null;
    }
  } catch (error) {
    console.error("Login error:", error);
    showError("An error occurred at login. Please try again.");
    return null;
  }
}

export async function getUserInfo(): Promise<LoginResult | null> {
  const config = getPostOptions(null);

  try {
    const response = await post(
      `${CbsOldApiUrl}/accountApi/tryGetUserInfo`,
      null,
      config
    );

    if (response.success) {
      return response.result as LoginResult;
    } else {
      showError(
        "An error occurred while loading your user profile. Please try again."
      );
      return null;
    }
  } catch (error) {
    console.error("Get user info error:", error);
    return null;
  }
}

export type UpdateUserInfoRequest = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  guid: string;
  dateFormat: string;
  timeFormat: string;
  preferredRegion: string;
};

export function updateUserInfo(updateUserInfoRequest: UpdateUserInfoRequest) {
  const url = `${CbsOldApiUrl}/accountApi/updateAccountInfo`;
  return post(url, updateUserInfoRequest);
}

export type ResetUserPasswordRequest = {
  websiteGuid: string;
  clientGuid: string;
  password: string;
  confirmPassword: string;
};
export function resetUserPassword(
  updateUserInfoRequest: ResetUserPasswordRequest
): Promise<SimpleApiResponse> {
  const url = `${CbsOldApiUrl}/accountApi/resetUserPassword`;
  return post(url, updateUserInfoRequest);
}

export type ResetMyPasswordRequest = {
  password: string;
  confirmPassword: string;
  currentPassword: string;
};
export function resetMyPassword(
  updateUserInfoRequest: ResetMyPasswordRequest
): Promise<SimpleApiResponse> {
  const url = `${CbsOldApiUrl}/accountApi/resetMyPassword`;
  return post(url, updateUserInfoRequest);
}

export type ResetDriverPasswordRequest = {
  driverGuid: string;
  password: string;
  confirmPassword: string;
};

export function resetDriverPassword(
  request: ResetDriverPasswordRequest
): Promise<SimpleApiResponse> {
  const url = `${CbsOldApiUrl}/accountApi/resetDriverPassword`;
  return post(url, request);
}

export function refreshUserToken() {
  const requestOptions = getPostOptions(null);
  return fetch(`${CbsOldApiUrl}/accountApi/refreshToken`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result.success) {
        return result.result as LoginResult;
      } else {
        showError(
          "An error occurred while loading your user profile. Please try again."
        );
        //return [] as ClientInfo[];
      }
      return null;
    })
    .catch((error) => console.log("error", error));
}

export function setDefaultWebsite(websiteGuid: string) {
  const requestOptions = getPostOptions(null);
  return fetch(
    `${CbsOldApiUrl}/accountApi/setDefaultWebsite?websiteGuid=${websiteGuid}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
      return true;
    })
    .catch((error) => {
      console.log("error", error);
      showError(
        "There was an error when changing the default website. Please try again."
      );
      return false;
    });
}

//#endregion User management API

export function getIntervalStats(
  startDate: Date,
  endDate: Date,
  interval: number
): Promise<IntervalStatsInfo | null> {
  const requestOptions = getGetOptions();
  const start = encodeURI(moment(startDate).toISOString());
  const end = encodeURI(moment(endDate).toISOString());
  const url = `${ExternalApiUrl}/BookingDotCom/get-stats?FromDate=${start}&ToDate=${end}&IntervalInMinutes=${interval}`;

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
      return result as IntervalStatsInfo;
    })
    .catch((error) => {
      console.log("error", error);
      showError(
        "There was an error when retrieving booking.com requests. Please try again."
      );
      return null;
    });
}

export type GetSearchRoutesRequest = {
  regionCenter: LatLng;
  radiusInMeters: number;
  minimumCount: number;
};

export type GetSearchRoutesResponse = {
  source: string;
  fromPlaceId: string | null;
  fromName: string;
  fromAddress: string;
  fromCity: string;
  fromCountry: string;
  fromPostalCode: string;
  fromIata: string | null;
  ianaTimezone: string;
  from: LatLng;
  // Add other fields here if needed
};

export function getSearchRequests(
  request: GetSearchRoutesRequest
): Promise<WebApiResponse<GetSearchRoutesResponse>> {
  const url = `${ExternalApiUrl}/BookingDotCom/getRoutes-test`;
  return post(url, request);
}

export function BookingDotComRequests() {}

export function getBookingDotComRequestsDownloadUrl(
  startDate: Date,
  endDate: Date,
  maxCount: number = 900000,
  source: string = "api"
) {
  const start = encodeURI(moment(startDate).toISOString());
  const end = encodeURI(moment(endDate).toISOString());
  if (source === "api") {
    return `${ExternalApiUrl}/BookingDotCom/get-requests?FromDate=${start}&ToDate=${end}&NumberOfItems=${maxCount}`;
  } else {
    return `${ExternalApiUrl}/BookingDotCom/get-requests-excel?FromDate=${start}&ToDate=${end}&NumberOfItems=${maxCount}`;
  }
}

export function getBookingDotComRequests(
  startDate: Date,
  endDate: Date
): Promise<any> {
  const start = encodeURI(moment(startDate).toISOString());
  const end = encodeURI(moment(endDate).toISOString());
  const url = `${EntitiesApiUrl}/api/Bookingdotcom/requests?FromDate=${start}&ToDate=${end}&NumberOfItems=900000`;
  return getResponse(url)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log("getBookingDotComRequests error:", error);
      showError(
        "There was an error when retrieving booking.com requests. Please try again."
      );
      return null;
    });
}

export function getWebsiteClients(websiteGuid: string): Promise<any> {
  return getResponse(`${CbsApiUrl()}/api/clients/getClients/${websiteGuid}`);
}

export function getCompaniesAndClients(websiteGuid: string): Promise<any> {
  return getResponse(`${CbsApiUrl()}/api/clients/getCompaniesAndClients/${websiteGuid}?includeAgents=true`);
}
export function getWebsiteClient(clientGuid: string): Promise<WebApiResponse<ClientInfo>> {
  return getResponse(`${CbsApiUrl()}/api/clients/${clientGuid}`);
}

export function getWebsiteDrivers(websiteGuid: string): Promise<DriverInfo[]> {
  return getResponse(
    `${CbsApiUrl()}/api/drivers/getWebsiteDrivers?websiteGuid=${websiteGuid}`
  );
}

export function getWebsiteRegions(websiteGuid: string): Promise<RegionInfo[]> {
  const requestOptions = getGetOptions();
  return fetch(
    `${CbsApiUrl()}/api/Reports/GetRegions?websiteGuid=${websiteGuid}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      //console.log(result);
      if (result.success) {
        return result.data as RegionInfo[];
      } else {
        showError("An error occurred while loading regions. Please try again.");
        return [] as RegionInfo[];
      }
    })
    .catch((error) => {
      //console.log('error', error);
      showError("An error occurred while loading regions. Please try again.");
      return [] as RegionInfo[];
    });
}

export function getProfitReportsLink(
  isApi: boolean,
  websiteGuid: string,
  startDate: Date,
  endDate: Date,
  clientId: string,
  selectedRegion: string,
  selectedDriverGuid: string,
  selectedSupplierGuid: string,
  includeCancelled: boolean,
  includeNoDriver: boolean,
  type: string
) {
  const start = encodeURI(moment(startDate).toISOString());
  const end = encodeURI(moment(endDate).toISOString());

  var url = isApi
    ? `${CbsApiUrl()}/api/Reports/BookingStats?WebsiteGuid=${websiteGuid}&FromDate=${start}&ToDate=${end}&Type=${type}`
    : `${CbsApiUrl()}/api/Reports/ExportToExcel?WebsiteGuid=${websiteGuid}&FromDate=${start}&ToDate=${end}&Type=${type}`;
  if (selectedDriverGuid) {
    url += `&driverguid=${selectedDriverGuid}`;
  }
  if (selectedSupplierGuid) {
    url += `&supplierGuid=${selectedSupplierGuid}`;
  }
  if (includeCancelled) {
    url += `&IncludeCancelled=${includeCancelled}`;
  }
  if (includeNoDriver) {
    url += `&IncludeNoDriver=${includeNoDriver}`;
  }
  if (clientId && clientId !== GuidEmpty) {
    url += `&ClientUserId=${clientId}`;
  }
  if (selectedRegion) {
    url += `&RegionGuid=${selectedRegion}`;
  }
  return url;
}

export function getProfitReports(
  websiteGuid: string,
  startDate: Date,
  endDate: Date,
  clientId: string,
  selectedRegion: string,
  selectedDriverGuid: string,
  selectedSupplierGuid: string,
  includeCancelled: boolean,
  includeNoDriver: boolean,
  type: string
): Promise<PerformanceInfo[] | null> {
  var requestOptions = {
    ...getGetOptions(),
    timeout: 240 * 1000, // Timeout in milliseconds
  };
  
  const url = getProfitReportsLink(
    true,
    websiteGuid,
    startDate,
    endDate,
    clientId,
    selectedRegion,
    selectedDriverGuid,
    selectedSupplierGuid,
    includeCancelled,
    includeNoDriver,
    type
  );

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      //console.log(result);
      if (result.success) {
        return result.data as PerformanceInfo[];
      } else {
        showError("An error occurred while loading reports. Please try again.");
        return [] as PerformanceInfo[];
      }
    })
    .catch((error) => {
      //console.log('error', error);
      showError("An error occurred while loading reports. Please try again.");
      return [] as PerformanceInfo[];
    });
}

//#region Clients API

export function deleteClient(clientId: string) {
  const url = `${CbsApiUrl()}/api/Clients/deleteClient?clientId=${clientId}`;
  return post(url, null);
}

export function updateClient(client: Client) {
  const url = `${CbsApiUrl()}/api/Clients/updateClient`;
  return post(url, client);
}

//#endregion Clients API

//#region Coupons API

export function getWebsiteCoupons(websiteGuid: string): Promise<Coupon[]> {
  return getResponse(`${CbsApiUrl()}/api/Coupon/getCoupons/${websiteGuid}`);
}

export function addCoupon(coupon: Coupon) {
  const url = `${CbsApiUrl()}/api/Coupon/addCoupon`;
  return post(url, coupon);
}
// 1c2c3bf1-39b3-480b-8136-44884351c2a6
// export function deleteCoupon(couponId: string) {
//   const url = `${CbsApiUrl()}/api/Coupons/deleteCoupon/${couponId}`;
//   return post(url, null);
// }

export function updateCoupon(client: Coupon) {
  const url = `${CbsApiUrl()}/api/Coupon/updateCoupon`;
  return post(url, client);
}

export function enableCoupon(
  websiteGuid: string,
  couponId: string,
  isEnabled: boolean
) {
  const url = `${CbsApiUrl()}/api/Coupon/enableCoupon/${websiteGuid}/${couponId}?IsEnabled=${isEnabled}`;
  return post(url, null);
}

//#endregion Coupons API

//#region Suppliers API

export function getWebsiteSuppliers(
  websiteGuid: string
): Promise<SupplierInfo[]> {
  //const requestOptions = getGetOptions();
  const url = `${CbsApiUrl()}/api/Suppliers/getSuppliers?websiteGuid=${websiteGuid}`;
  return (
    getResponse(url) //fetch(`${CbsApiUrl()}/api/Suppliers/getSuppliers?websiteGuid=${websiteGuid}`, requestOptions)
      //.then(response => response.json())
      .then((result) => {
        //console.log(result);
        if (result.success) {
          return result.data as SupplierInfo[];
        } else {
          showError(
            "An error occurred while loading suppliers. Please try again."
          );
          return [] as SupplierInfo[];
        }
      })
      .catch((error) => {
        //console.log('error', error);
        showError(
          "An error occurred while loading suppliers. Please try again."
        );
        return [] as SupplierInfo[];
      })
  );
}

export function getSuppliers(websiteGuid: string): Promise<SupplierInfo[]> {
  //const requestOptions = getGetOptions();
  const url = `${EntitiesApiUrl}/api/suppliers?websiteGuid=${websiteGuid}`;
  return (
    getResponse(url) //fetch(`${CbsApiUrl()}/api/Suppliers/getSuppliers?websiteGuid=${websiteGuid}`, requestOptions)
      //.then(response => response.json())
      .then((result) => {
        //console.log(result);
        if (result.success) {
          return result.data as SupplierInfo[];
        } else {
          showError(
            "An error occurred while loading suppliers. Please try again."
          );
          return [] as SupplierInfo[];
        }
      })
      .catch((error) => {
        //console.log('error', error);
        showError(
          "An error occurred while loading suppliers. Please try again."
        );
        return [] as SupplierInfo[];
      })
  );
}

// export function getSuppliers(websiteGuid: string): Promise<SupplierInfo[]> {
//   //const requestOptions = getGetOptions();
//   const url = `${EntitiesApiUrl}/api/suppliers?websiteGuid=${websiteGuid}`;
//   return (
//     getResponse(url) //fetch(`${CbsApiUrl()}/api/Suppliers/getSuppliers?websiteGuid=${websiteGuid}`, requestOptions)
//       //.then(response => response.json())
//       .then((result) => {
//         //console.log(result);
//         if (result.success) {
//           return result.data as SupplierInfo[];
//         } else {
//           showError("An error occurred while loading suppliers. Please try again.");
//           return [] as SupplierInfo[];
//         }
//       })
//       .catch((error) => {
//         //console.log('error', error);
//         showError("An error occurred while loading suppliers. Please try again.");
//         return [] as SupplierInfo[];
//       })
//   );
// }

export function getWebsiteRegionsForSuppliers(
  websiteGuid: string
): Promise<WebApiResponse<RegionInfo[]>> {
  const url = `${CbsApiUrl()}/api/Prices/getRegions/${websiteGuid}`;
  return getFromApi(url);
}

export function getWebsiteBookingDotComPricePlans(
  websiteGuid: string
): Promise<WebApiResponse<BookingDotComPricePlan[]>> {
  const url = `${CbsOldApiUrl}/pricingApi/getPricePlans/${websiteGuid}/ba96cd7c-bdbb-4477-b6ca-25c863a768a3`;
  return getFromApi(url);
}

export function getFixedPricePlans(
  websiteGuid: string,
  priceplanguid: string
): Promise<WebApiResponse<any>> {
  const url = `${CbsOldApiUrl}/pricingApi/getWebsiteFixedPrices/${websiteGuid}/${priceplanguid}`;
  return getFromApi(url);
}

export function updateSupplier(supplier: SupplierInfo) {
  //console.log("updateSupplier: ", supplier)
  const url = `${CbsApiUrl()}/api/Suppliers/updateSupplier`;
  return post(url, supplier);
}

export function addSupplier(supplier: AddNewSupplierRequest) {
  //console.log("addNewSupplier: ", supplier)
  const url = `${CbsApiUrl()}/api/Suppliers/addNewSupplier`;
  return post(url, supplier);
}

export function inviteSupplier(supplierInfo: InviteSupplierRequest) {
  const url = `${CbsApiUrl()}/api/Suppliers/inviteSupplier`;
  console.log("supplier invite: ", supplierInfo);
  return post(url, supplierInfo);
}

export function getSupplierInvitation(invitationGuid: string) {
  const url = `${CbsApiUrl()}/api/Suppliers/getSupplierInvitation?id=${invitationGuid}`;
  //console.log("supplier invite: ", supplierInfo)
  return getResponse(url);
}

export function registerCompany(supplierInfo: InviteSupplierRequest) {
  const url = `${CbsApiUrl()}/api/Suppliers/registerCompany`;
  console.log("supplier invite: ", supplierInfo);
  return post(url, supplierInfo);
}

export function createClientOnSupplier(supplier: CreateClientRequest) {
  //console.log("addNewSupplier: ", supplier)
  const url = `${CbsApiUrl()}/api/Suppliers/createClientOnSupplier`;
  return post(url, supplier);
}

export function deleteSupplier(supplierId: string) {
  //console.log("deleteSupplier: ", supplier)
  const url = `${CbsApiUrl()}/api/Suppliers/deleteSupplier?supplierId=${supplierId}`;
  return post(url, null);
}

export function getSupplierPrice(bookingGuid: string, supplierGuid: string) {
  const url = `${CbsOldApiUrl}/priceCalculationApi/getSupplierPrices`;
  return post(url, { bookingGuid: bookingGuid, supplierGuid: supplierGuid });
}

export function getSupplierPriceV2(
  bookingGuid: string,
  supplierGuid: string
): Promise<WebApiResponse<BookingPriceInfo>> {
  const url = `${ExternalApiUrl}/connect/getSupplierPrices`;
  return post(url, {
    bookingGuid: bookingGuid,
    supplierWebsiteGuid: supplierGuid,
  });
}

export function supplierAcceptBooking(bookingGuid: string) {
  const url = `${CbsApiUrl()}/api/bookings/AcceptBooking`;
  return post(url, { guid: bookingGuid });
}

export function supplierDeclineBooking(bookingGuid: string) {
  const url = `${CbsApiUrl()}/api/bookings/RejectBooking`;
  return post(url, { guid: bookingGuid });
}

export function removeSupplierFromBooking(request: {
  bookingGuid: string;
  supplierGuid?: string;
  supplierWebsiteId?: number;
}) {
  const url = `${CbsApiUrl()}/api/bookings/RemoveDriverOrSupplier`;
  return post(url, request);
}
//#endregion Suppliers API

//#region Create / Update / View Booking

export function getBookingPrice(request) {
  const url = `${CbsOldApiUrl}/BookingApi/getBookingPrices`;
  return post(url, request);
}

export function getNewBookingDetails_Old() {
  const url = `${CbsOldApiUrl}/BookingApi/GetBooking/website`;
  return getResponse(url);
}

export function getNewBookingDetails(guid: string) {
  //http://localhost:51438/BookingApi/GetBooking/2f035a39-6910-489a-a68f-bbd71cef4bcd
  const url = `${CbsOldApiUrl}/BookingApi/GetBooking/${guid}`;
  return getResponse(url);
}

export function saveBooking_Old(bookingCreatePayload: any) {
  //console.log("SaveBooking payload: ", bookingCreatePayload);
  const url = `${CbsOldApiUrl}/BookingApi/SaveBooking`;
  return post(url, bookingCreatePayload);
}

export function saveClientBooking_Old(bookingCreatePayload: any) {
  //console.log("SaveClientBooking payload: ", bookingCreatePayload);
  const url = `${CbsOldApiUrl}/BookingApi/SaveClientBooking`;
  return post(url, bookingCreatePayload);
}

export function saveClientBooking(saveBookingPayload: any) {
  const url = `${CbsApiUrl()}/api/clientBookings/SaveBooking`;
  return post(url, saveBookingPayload);
}

export function updateBookingDriverStatus(
  model: SetBookingDriverStatusPayload
) {
  const url = `${CbsOldApiUrl}/BookingApi/SetBookingDriverStatuses`;
  return post(url, model);
}

export function getBookingPenalty(bookingGuid: string) {
  const url = `${CbsOldApiUrl}/BookingApi/getBookingPenalty`;
  return post(url, { bookingGuid: bookingGuid });
}

export function setBookingPenalty(
  bookingGuid: string,
  amount: number,
  description: string
) {
  const url = `${CbsOldApiUrl}/BookingApi/SetBookingPenalty`;
  return post(url, {
    bookingGuid: bookingGuid,
    amount: amount,
    reason: description,
  });
}

export function getBookingCancellationInfo(guid: string) {
  const url = `${CbsOldApiUrl}/ABookingApi/GetBookingCancellationInfo/${guid}`;
  return getResponse(url);
}

export function cancelBooking(
  bookingCancellationPayload: BookingCancellationRequest
) {
  console.log("cancelBooking", bookingCancellationPayload);
  const url = `${CbsOldApiUrl}/ABookingApi/CancelBooking`;
  return post(url, bookingCancellationPayload);
}

export function getBookingChangesLog(bookingGuid: string) {
  const url = `${CbsApiUrl()}/api/bookings/getBookingChangesLogInfo/${bookingGuid}`;
  return getResponse(url);
}

export function getBookingChangesLog_Old(bookingGuid: string) {
  const url = `${CbsOldApiUrl}/bookingApi/getBookingChangesLogInfo/${bookingGuid}`;
  return getResponse(url);
}

export function getBookingDriverEvents(bookingGuid: string) {
  const url = `${CbsOldApiUrl}/bookingApi/getBookingLogInfo/${bookingGuid}`;
  return getResponse(url);
}

export function getBookingsCacheInfo() {
  const url = `${CbsApiUrl()}/api/bookings/getBookingsCacheInfo`;
  return getResponse(url, 300);
}

export function reloadBookingsInCache(numberOfDays) {
  const url = `${CbsApiUrl()}/api/bookings/refreshBookingsCacheForNextDays/${numberOfDays}`;
  return getResponse(url);
}

export function resetBookingsCache() {
  const url = `${CbsApiUrl()}/api/bookings/resetBookingsCache`;
  return getResponse(url);
}

//#endregion

/* Live Map API */

export function getActiveDrivers(payload: {
  websiteGuid: string;
  timeoutInSeconds: number;
}) {
  const url = `${CbsApiUrl(false)}/drivers/getActiveDrivers`;
  return post(url, payload);
}

/* END: Live Map API */
/* Dispatch API */

export function getDriverLocation(hash: string) {
  const url = `${CbsApiUrl()}/drivers/getDriverLocation/${hash}`;
  return getResponse(url);
}

export function getBookingDetails(guid: string) {
  const url = `${CbsApiUrl()}/api/bookings/GetBooking/${guid}`;
  //`${CbsOldApiUrl}/BookingApi/GetBookingDetailsForAdmin/${guid}`;
  return getResponse(url);
}

export function getDrivers(websiteGuid: string) {
  const url = `${CbsApiUrl()}/api/drivers?websiteGuid=${websiteGuid}`;
  return getResponse(url);
}

export function getDriverInfo(driverGuid: string) {
  const url = `${CbsApiUrl()}/api/drivers/${driverGuid}`;
  return getResponse(url);
}
export function createDriver(request: SaveDriverRequest) {
  const url = `${CbsApiUrl()}/api/drivers`;
  return post(url, request);
}

export function saveDriver(id: string, request: SaveDriverRequest) {
  const url = `${CbsApiUrl()}/api/drivers/${id}`;
  return put(url, request);
}

export function deleteDriver(id: string) {
  const url = `${CbsApiUrl()}/api/drivers/${id}`;
  return del(url);
}

export function createVehicle(id: string, request: SaveVehicleRequest) {
  const url = `${CbsApiUrl()}/api/drivers/${id}/cars`;
  return post(url, request);
}

export function getVehicleInfo(driverId: string, vehicleId: string) {
  const url = `${CbsApiUrl()}/api/drivers/${driverId}/cars/${vehicleId}`;
  return getResponse(url);
}

export function saveVehicle(
  driverId: string,
  vehicleId: string,
  request: SaveVehicleRequest
) {
  const url = `${CbsApiUrl()}/api/drivers/${driverId}/cars/${vehicleId}`;
  return put(url, request);
}

export function deleteVehicle(driverId: string, vehicleId: string) {
  const url = `${CbsApiUrl()}/api/drivers/${driverId}/cars/${vehicleId}`;
  return del(url);
}

export function getServices() {
  const url = `${CbsOldApiUrl}/driversApi/GetCarClasses`;
  return getResponse(url);
}

export function getMeetAndGreet(bookingGuid: string) {
  const url = `${CbsOldApiUrl}/bookings/ws/${bookingGuid}`;
  return url;
}

export function updateService(service: IVehicle) {
  const url = `${EntitiesApiUrl}/api/Vehicles`;
  return post(url, service);
}

export interface SaveDriverRequest {
  websiteGuid: string;
  firstName: string;
  lastName: string;
  phone: string;
  gender: number;
  paymentMethod: string;
  paymentAccount: string;
  paymentPeriod: string;
  nationality: string;
  ianaTimezone: string;
  additionalInternalInfo: string;
  additionalClientInfo: string;
  photo: Photo;
}

interface Photo {
  id: string;
  type: string;
  publicUrl: string;
}

export interface Booking {
  guid: string;
  customerId: string;
  websiteId: number;
  websiteGuid: string;
  pickupDateTimeMoment: string;
  gmtPickupDateTimeMoment: string;
  localPickupDateTime: string;
  gmtPickupDateTime: string;
  from: LocationInfo;
  to: LocationInfo;
  metricUnit: number;
  distanceInMiles: number;
  distanceInKilometers: number;
  durationInMinutes: number;
  customerName: string;
  accountName: string;
  isSupplierBooking: boolean;
  supplierStatus: number;
  isProviderBooking: boolean;
  driverUrl: string;
  clientUrl: string;
  driver: DriverInfo;
  adminStatus: number;
  bookingType: number;
  isCancelled: boolean;
  externalRefNumber: string;
  showProfitIgnoringTax: boolean;
  driverAcceptanceStatus: number;
  driverOnRouteStatus: number;
  driverResponses: number;
  isBidInProgress: boolean;
  source: string;
  carClassName: string;
  isInProgress: boolean;
  isFinished: boolean;
  status: string;
  statusClass: string;
  timeLeft: number;
  bookingPrice: number;
  paymentStatusEnum: number;
  paymentStatusString: string;
  totalPrice: number;
  totalIncome: number;
  totalTaxes: number;
  profit: number;
  canAssignDriver: boolean;
  canAcceptReject: boolean;
  canRestore: boolean;
  currency: string;
  paymentMethod: string;
  region: string;
  driverCurrency: string;
  hasVat: boolean;
  numberOfExtras: number;
  passengerDetails: PassengerDetails;
  isSupportOperator: boolean;
  isAdmin: boolean;
  isDispatcher: boolean;
  isDataEntry: boolean;
  regionGuid: string;
}

interface LocationInfo {
  isAirport: boolean;
  address: string;
  addressName: string;
  latLng: LatLng;
}

interface PassengerDetails {
  salutation: string;
  firstName: string;
  phone: string;
  adultsNumber: number;
  adults: number;
  hasMeetAndGreet: boolean;
  flightNumber?: string;
}

export function getNextBookings(
  websiteGuid: string,
  numberOfDays: string | null,
  startDate: Moment,
  endDate: Moment,
  statuses: string[],
  searchText: string
): Promise<Booking[]> {
  const url = `${CbsApiUrl()}/api/bookings/GetNextBookingsForAdmin`;
  const start = startDate ? encodeURI(startDate.toISOString()) : null;
  const end = endDate ? encodeURI(endDate.toISOString()) : null;
  const timezone = start && end ? "local" : null;
  if (start && end) {
    numberOfDays = null;
  }

  const payload = {
    numberOfDays: numberOfDays,
    startDate: start,
    endDate: end,
    timezone: timezone,
    websitesGuids: [websiteGuid],
    status: "all",
    statuses: statuses,
    searchText: searchText,
  };

  return post(url, payload).then((data) => {
    if (data) {
      return data.data;
    }
  });
}

export function getNextBookingsBetweenDates(
  websiteGuid: string,
  start: string,
  end: string
) {
  //console.log("getNextBookingsBetweenDates");
  //const url = `${CbsOldApiUrl}/BookingApi/GetNextBookingsForAdmin`;
  const url = `${CbsApiUrl()}/api/bookings/bookingApi/GetNextBookingsForAdmin`;
  const payload = {
    startDate: start,
    endDate: end,
    websitesGuids: [websiteGuid],
    status: "all",
  };

  return post(url, payload).then((data) => {
    if (data) {
      //console.log("getNextBookings result", data);
      //return data.items;
      return data.result;
    }
  });
}

export function getClientBookings(
  websiteGuid: string,
  start: string,
  end: string
) {
  //console.log(`getClientBookings ${websiteGuid} ${start} ${end}`);
  const url = `${CbsApiUrl()}/api/clientBookings/GetBookings`;
  const payload = {
    startDate: start,
    endDate: end,
    websitesGuids: [websiteGuid],
    numberOfDays: 0,
    searchText: null,
    pageIndex: 0,
    pageSize: 0,
  };

  return post(url, payload);
}

export interface DriverAcceptBookingRequest {
  bookingGuid: string;
  driverGuid: string;
  price?: number;
  location?: LatLng;
}
export function acceptDriverBooking(
  payload: DriverAcceptBookingRequest
): Promise<SimpleApiResponse> {
  const url = `${CbsApiUrl()}/api/driverBookings/AcceptDriverBooking`;
  return post(url, payload);
}

export interface DriverRejectBookingRequest {
  driverGuid: string;
  bookingGuid: string;
  reason: string;
  location?: LatLng;
  driverAddress?: string;
}
export function rejectDriverBooking(
  payload: DriverRejectBookingRequest
): Promise<SimpleApiResponse> {
  const url = `${CbsApiUrl()}/api/driverBookings/RejectDriverBooking`;
  return post(url, payload);
}

export function updateDriverRouteStatus(
  bookingId: number,
  websiteGuid: string,
  onRouteStatus: number
) {
  //console.log(`updateDriverRouteStatus ${bookingId} ${websiteGuid} ${onRouteStatus}`);
  const url = `${CbsApiUrl()}/api/driverBookings/UpdateDriverBookingStatus`;
  const payload = {
    bookingId: bookingId,
    websitesGuids: [websiteGuid],
    onRouteStatus: onRouteStatus,
  };

  return post(url, payload);
}

export function getClientBookingCancellationInfo(
  bookingGuid: string
): Promise<ClientBookingCancellationRequest> {
  const url = `${CbsApiUrl()}/api/clientBookings/GetBookingCancellationInfo?bookingGuid=${bookingGuid}`;
  return get(url);
}

type ClientBookingCancellationRequest = {
  guid: string;
  cancelationCost: number;
  currency: string;
  hash: string;
  reason: string;
  reasonDescription: string;
};

export function cancelClientBooking(
  bookingCancellationPayload: ClientBookingCancellationRequest
) {
  //console.log("cancelClientBooking", bookingCancellationPayload);
  const url = `${CbsApiUrl()}/api/clientBookings/CancelBooking`;
  return post(url, bookingCancellationPayload);
}

export function getBooking(guid: string) {
  const url = `${CbsApiUrl()}/api/bookings/GetBooking/${guid}`;
  return get(url);
}

interface DriversToAssignResponse {
  drivers: Driver[];
  bookingGuid: string;
  driverGuid: string;
  serviceGuid: string;
  sendConfirmationEmailToDriver: boolean;
  sendConfirmationSmsToDriver: boolean;
  sendConfirmationEmailToClient: boolean;
}

interface Driver {
  id: string;
  fullName: string;
  currency: string;

  isHired: boolean;
  isSupplier: boolean;
  email: string;
  phone: string;
  carClass: string;
  regions: PartnerRegion[];
}

interface PartnerRegion {
  id: string;
  name: string;
}

export interface UpdateBookingPaymentRequest {
  bookingGuid: string;
  newPayment: number;
}

export function getDriversToAssign(
  bookingGuid: string
): Promise<ApiResponse<DriversToAssignResponse>> {
  const url = `${CbsApiUrl()}/api/bookings/GetDriversToAssign`;
  const payload = { bookingGuid: bookingGuid };
  return post(url, payload);
}

export function removeDriverFromBooking(request: {
  bookingGuid: string;
  driverGuid: string;
  supplierGuid: string;
}) {
  const url = `${CbsApiUrl()}/api/bookings/RemoveDriverOrSupplier`;
  return post(url, request);
}

export function assignDriver(assignDriverRequest: AssignDriverRequest) {
  const url = `${CbsApiUrl()}/api/bookings/AssignDriver`;
  return post(url, assignDriverRequest);
}

export function updateBookingPayment(updateBookingPaymentRequest: UpdateBookingPaymentRequest) {
  const url = `${CbsApiUrl()}/api/bookings/UpdateBookingPayment`;
  return patch(url, updateBookingPaymentRequest);
}

export function assignOneTimeDriver(assignDriverRequest: AssignDriverRequest) {
  const url = `${CbsApiUrl()}/api/bookings/AssignOneTimeDriver`;
  return post(url, assignDriverRequest);
}

export function sendSms(sendSmsModel: SendSmsRequest) {
  //console.log("sending sms...", sendSmsModel);
  const url = `${CbsOldApiUrl}/SmsApi/sendSmsMessage`;
  return post(url, sendSmsModel);
}

/* END: Dispatch API */

/* FlightStats API */
export interface FlightArrivalDateRequest {
  flightNumber: string;
  date: string;
  airport:  string
}

export interface IsAirportRequest {
  latLng: {
    lat: number;
    lng: number;
  };
  radiusInMeters: number;
}

interface Delays {
  departureGateDelayMinutes: number;
  arrivalGateDelayMinutes: number;
}

interface FlightStatus {
  flightId: number;
  carrierFsCode: string;
  flightNumber: string;
  departureAirportFsCode: string;
  arrivalAirportFsCode: string;
  departureDate: DateInfo;
  arrivalDate: DateInfo;
  status: string;
  schedule: Schedule;
  operationalTimes: OperationalTimes;
  codeshares: any[];
  delays: Delays;
  flightDurations: FlightDurations;
  airportResources: AirportResources;
  flightEquipment: FlightEquipment;
}

interface DateInfo {
  dateUtc: string;
  dateLocal: string;
}

interface Schedule {
  flightType: string;
  serviceClasses: string;
  restrictions: string;
  uplines: any[];
  downlines: any[];
}

interface OperationalTimes {
  publishedDeparture: DateInfo;
  scheduledGateDeparture: DateInfo;
  estimatedGateDeparture: DateInfo;
  actualGateDeparture: DateInfo;
  estimatedRunwayDeparture: DateInfo;
  actualRunwayDeparture: DateInfo;
  publishedArrival: DateInfo;
  scheduledGateArrival: DateInfo;
  estimatedGateArrival: DateInfo;
  actualGateArrival: DateInfo;
  estimatedRunwayArrival: DateInfo;
  actualRunwayArrival: DateInfo;
}

interface FlightDurations {
  scheduledBlockMinutes: number;
  blockMinutes: number;
  scheduledAirMinutes: number;
  airMinutes: number;
  scheduledTaxiOutMinutes: number;
  taxiOutMinutes: number;
  scheduledTaxiInMinutes: number;
  taxiInMinutes: number;
}

interface AirportResources {
  departureTerminal: string;
  departureGate: string;
  arrivalTerminal: string;
  arrivalGate: string;
}

interface FlightEquipment {
  scheduledEquipmentIataCode: string;
  actualEquipmentIataCode: string;
  tailNumber: string;
  fleetAircraftId: number;
}

interface Appendix {
  airlines: Airline[];
  airports: Airport[];
  equipments: Equipment[];
}

interface Airline {
  fs: string;
  iata: string;
  icao: string;
  name: string;
  active: boolean;
}

interface Airport {
  fs: string;
  iata: string;
  icao: string;
  faa: string;
  name: string;
  city: string;
  cityCode: string;
  countryCode: string;
  countryName: string;
  regionName: string;
  timeZoneRegionName: string;
  weatherZone: string;
  localTime: string;
  utcOffsetHours: number;
  latitude: number;
  longitude: number;
  elevationFeet: number;
  classification: number;
  active: boolean;
  weatherUrl: string;
  delayIndexUrl: string;
}

interface Equipment {
  iata: string;
  name: string;
  turboProp: boolean;
  jet: boolean;
  widebody: boolean;
  regional: boolean;
}

export interface FlightData {
  flightStatuses: FlightStatus[];
  appendix: Appendix;
}

interface Result {
  icao: string;
  iata: string;
  name: string;
  city: string;
  state: string;
  country: string;
  elevation: number;
  lat: number;
  lon: number;
  tz: string;
  waitingTimeInMinutes: number;
}
interface IsAirportResponse {
  result: Result;
  code: number;
  success: boolean;
  errorMessages: string[];
  successMessages: string[];
  errors: string[];
  redirectAfterMs: number;
}

export function getIsAirport(
  isAirportRequest: IsAirportRequest
): Promise<IsAirportResponse> {
  const url = `${CbsApiUrl()}/staticdata/getAirport`;
  return post(url, isAirportRequest);
}

export function getFlightArrivalDate(
  flightArrivalDateRequest: FlightArrivalDateRequest
): Promise<FlightData> {
  const url = `${EntitiesApiUrl}/api/flights/status/flight/arrival-date`;
  return post(url, flightArrivalDateRequest);
}
/* END:FlightStats API */

/* Goldsainte API */

export interface CarOwnerRegistratonRequest {
  websiteGuid: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
  acceptTermsAndConditions: boolean;
}
export function registerCarOwner(
  carOwnerRegistrationRequest: CarOwnerRegistratonRequest
) {
  const url = `${GoldsainteApiUrl}/account/registerCarOwner`;
  return post(url, carOwnerRegistrationRequest);
}

export function getBookingDetailsForDriverByHash(hash: string) {
  const url = `${GoldsainteApiUrl}/bookings/getDriverBookingInfo`;
  return post(url, { hash: hash });
}

export function addOrUpdateCarOwner(addOrUpdateCarOwnerRequest) {
  const url = `${GoldsainteApiUrl}/carrental/addOrUpdateCarOwner`;
  return post(url, addOrUpdateCarOwnerRequest);
}

export function updateCarRentalAfterBookingUpdate(bookingGuid: string) {
  const url = `${GoldsainteApiUrl}/carrental/updateCarRental/${bookingGuid}`;
  console.log("updateCarRentalAfterBookingUpdate", url);
  return post(url, {});
}

export function getCarOwners() {
  const url = `${GoldsainteApiUrl}/carrental/getCarOwners`;
  return getResponse(url);
}

export function getMyCarOwnerProfile() {
  const url = `${GoldsainteApiUrl}/carrental/getMyCarOwnerProfile`;
  return getResponse(url);
}

export interface UpdateMyCarOwnerProfileRequest {
  id: string;
  paypalId: string;
}
export function updateMyCarOwnerProfile(
  updateMyCarOwnerProfileRequest: UpdateMyCarOwnerProfileRequest
) {
  const url = `${GoldsainteApiUrl}/carrental/updateMyCarOwnerProfile`;
  return post(url, updateMyCarOwnerProfileRequest);
}

export function deleteCarOwner(guid: string) {
  const url = `${GoldsainteApiUrl}/carrental/deleteCarOwner`;
  return post(url, { entityId: guid });
}

export interface CarType {
  make: string;
  model: string;
  imageUrl: string;
  imageId: string;
  description: string;
  isRemoved: boolean;
  id: string;
  createdAt: string;
  updatedAt: string;
}
export function getCarTypes(): Promise<WebApiResponse<CarType[]>> {
  const url = `${GoldsainteApiUrl}/carrental/getCarTypes`;
  return getResponse(url);
}

export function deleteCarType(guid: string): Promise<WebApiResponse<CarType>> {
  const url = `${GoldsainteApiUrl}/carrental/deleteCarType`;
  return post(url, { entityId: guid });
}

export interface AddOrUpdateCarTypeRequest {
  id?: string;
  make: string;
  model: string;
  description: string;
  image: ImageFileInfo | null | undefined;
  //imageUrl: string | null | undefined;
  //imageId: string | null | undefined;
}

export interface ImageFileInfo {
  id: string;
  publicUrl: string;
}
export function addOrUpdateCarType(
  carTypeToUpdate: AddOrUpdateCarTypeRequest
): Promise<WebApiResponse<CarType>> {
  const url = `${GoldsainteApiUrl}/carrental/addOrUpdateCarType`;
  return post(url, carTypeToUpdate);
}

export function getCars() {
  const url = `${GoldsainteApiUrl}/carrental/getCars`;
  return getResponse(url);
}

export function getAvailableCars(getAvailableCarsRequest) {
  const url = `${GoldsainteApiUrl}/carrental/getAvailableCars`;
  return post(url, getAvailableCarsRequest);
}

export function addOrUpdateCar(addOrUpdateCarRequest) {
  const url = `${GoldsainteApiUrl}/carrental/addOrUpdateCar`;
  return post(url, addOrUpdateCarRequest);
}

export function updateCarAvailability(updateCarAvailabilityRequest) {
  const url = `${GoldsainteApiUrl}/carrental/updateCarAvailability`;
  return post(url, updateCarAvailabilityRequest);
}

export function deleteCar(guid: string) {
  const url = `${GoldsainteApiUrl}/carrental/deleteCar`;
  return post(url, { entityId: guid });
}

export interface GetCarRentalsRequest {
  websiteGuid: string;
  carOwnerGuid: string | null;
  numberOfDays: number | null;
  startDate: string | null;
  endDate: string | null;
}

export function getCarRentals(request: GetCarRentalsRequest) {
  const url = `${GoldsainteApiUrl}/carrental/getCarRentals`;
  return post(url, request);
}

export function getCarRentalForBooking(guid: string) {
  const url = `${GoldsainteApiUrl}/carrental/getCarRentalForBooking`;
  return post(url, { entityId: guid });
}

export interface AssignCarToCarRentalRequest {
  carRentalId: string;
  carToAssignId: string;
}

export function assignCarToCarRental(request: AssignCarToCarRentalRequest) {
  const url = `${GoldsainteApiUrl}/carrental/assignCarToCarRental`;
  return post(url, request);
}

export interface RejectCarRentalsRequest {
  carRentalId: string;
  message: string | null;
}
export function rejectCarRental(request: RejectCarRentalsRequest) {
  const url = `${GoldsainteApiUrl}/carrental/rejectCarRental`;
  return post(url, request);
}

/* END: Goldsainte API */

// Cars API
export function saveDriverCars(request: SaveCarsRequest) {
  const url = `${CbsApiUrl()}/api/Cars`;
  return post(url, request);
}
export function getDriverCars(
  id: string
): Promise<WebApiResponse<SaveCarsRequest>> {
  const url = `${CbsApiUrl()}/api/Cars/${id}`;
  return get(url);
}
function logout() {
  //store.dispatch({type: 'setUserInfo', data: {}})
  store.dispatch({ type: "setToken", data: null });
}

export async function getFromApi(url: string) {
  return getResponse(url);
}

export async function sendFormData(url: string, payload: any) {
  const state = store.getState();
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${state.user?.token}`,
    },
    method: "POST" as Method,
    timeout: 240 * 1000,
  };

  const formData = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    formData.append(key, value);
  });

  try {
    const response = await axios.post(url, formData, config);
    if (response.data?.isUnauthorized) {
      showToastSuccessAndErrorMessagesFromApiResponse(response.data);
      logout();
      return;
    }
    return response.data || response;
  } catch (error) {
    handleError(error);
  }
}

export async function postToApi(url: string, data: any) {
  return post(url, data);
}

export async function putToApi(url: string, data: any) {
  return put(url, data);
}

export async function getResponse(url: string, timeoutInSeconds = 60) {
  const state = store.getState();
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${state.user?.token}`,
    },
    method: "GET" as Method,
    timeout: timeoutInSeconds * 1000,
  };

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (err) {
    console.error("axios.getResponse error", err);
    throw err;
  }
}

function handleError(error: any) {
  if (error?.response?.status === 401) {
    console.log("Logout user ....");
    logout();
  } else {
    console.error("axios error", error);
    throw error;
  }
}

async function get(url: string) {
  const state = store.getState();
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${state.user?.token}`,
    },
    method: "GET" as Method,
    timeout: 60 * 1000,
  };

  try {
    const response = await axios.get(url, config);
    return response.data?.result || response.data?.data || null;
  } catch (err) {
    console.error("axios.get error", err);
    throw err;
  }
}

export async function del(url: string) {
  const state = store.getState();
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${state.user?.token}`,
    },
    method: "DELETE" as Method,
    timeout: 60 * 1000,
  };

  try {
    const response = await axios.delete(url, config);
    return {
      success: response.data?.success || false,
      data: response.data || null,
      error: response.data?.error || "Unknown error",
    };
  } catch (err) {
    console.error("axios.delete error", err);
    return { success: false, error: err.message || "Network error" };
  }
}

export async function patch(url: string, data?: any) {
  const state = store.getState();
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${state.user?.token}`,
    },
    method: "PATCH" as Method,
    timeout: 120 * 1000,
    responseType: "json" as ResponseType,
  };

  try {
    const response = await axios.patch(url, data, config);
    if (response.data?.isUnauthorized) {
      showToastSuccessAndErrorMessagesFromApiResponse(response.data);
      logout();
      return;
    }
    return response.data || response;
  } catch (error) {
    handleError(error);
  }
}

export async function getBlobResponse(url: string) {
  const state = store.getState();
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${state.user?.token}`,
    },
    method: "GET" as Method,
    timeout: 60 * 1000,
    responseType: "blob" as ResponseType,
  };

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (err) {
    console.error("axios.getBlobResponse error", err);
    throw err;
  }
}

export async function post(url: string, data?: any) {
  const state = store.getState();
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${state.user?.token}`,
    },
    method: "post" as Method,
    timeout: 120 * 1000,
    responseType: data?.responseType || ("json" as ResponseType),
  };

  try {
    const response = await axios.post(url, data, config);
    if (response.data?.isUnauthorized) {
      showToastSuccessAndErrorMessagesFromApiResponse(response.data);
      logout();
      return;
    }
    return response.data || response;
  } catch (error) {
    handleError(error);
  }
}

export async function put(url: string, data: any) {
  const state = store.getState();
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${state.user?.token}`,
    },
    method: "PUT" as Method,
    timeout: 90 * 1000,
  };

  try {
    const response = await axios.put(url, data, config);
    if (response.data?.isUnauthorized) {
      showToastSuccessAndErrorMessagesFromApiResponse(response.data);
      logout();
      return;
    }
    return response.data || response;
  } catch (error) {
    handleError(error);
  }
}

function getPostOptions(body: any): RequestInit {
  const state = store.getState();
  const headers = new Headers({
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  });

  if (state.user?.token) {
    headers.append("Authorization", `Bearer ${state.user.token}`);
  }

  return {
    method: "POST" as Method,
    headers,
    body,
    redirect: "follow",
  };
}

function getGetOptions(): RequestInit {
  const state = store.getState();
  const headers = new Headers({
    accept: "*/*",
    "Cache-Control": "no-cache",
  });

  if (state.user?.token) {
    headers.append("Authorization", `Bearer ${state.user.token}`);
  }

  return {
    method: "GET" as Method,
    headers,
  };
}

/* Type definitions */

export interface MessageInfo {
  /**
   * If the error is linked to a field in form -> this will contain the field name. Otherwise null
   */
  fieldName: string | null;
  /**
   * Error code used for client internationalization
   */
  code: number;
  /**
   * Message in English
   */
  message: string;
}

export interface WebApiResponse<T> {
  code: number;
  title: string;
  successMessages: MessageInfo[];
  errorMessages: MessageInfo[];
  success: boolean;
  data: T;
}

export type SimpleApiResponse = {
  code: number;
  title: string;
  successMessages: MessageInfo[];
  errorMessages: MessageInfo[];
  success: boolean;
};

export interface ApiResponse<T> {
  success: boolean;
  result: T;
  errors: string[];
  errorMessages: ErrorMessage[];
}

export interface ErrorMessage {
  code: number;
  message: string;
}

export interface LoginResult {
  guid: string;
  websiteGuid: string;
  preferredRegion: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  jwtToken: string;
  websites: WebsiteInfo[];
  dateFormat: string;
  timeFormat: string;
}

export interface WebsiteInfo {
  isDefault: boolean;
  websiteGuid: string;
  websiteName: string;
  roles: ("Driver" | "Administrator" | "Client" | "CarOwner")[];
}

export interface IntervalStatsInfo {
  regions: string[];
  statistics: IntervalStats[];
}

export interface IntervalStats {
  fromDate: string;
  toDate: string;
  intervalInMinutes: number;
  requestsCount: number;
  priceGivenCount: number;
  completedBookingsCount: number;
  pricePlanGuid: string;
  pricePlanName: string;
}

export interface RegionInfo {
  name: string;
  guid: string;
}
export interface BookingDotComPricePlan {
  name: string;
  guid: string;
  radiusInkilometers: number;
}

export interface AddNewSupplierRequest {
  websiteGuid: string;
  supplierEmail: string;
}

export interface InviteSupplierRequest {
  websiteGuid: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface CreateClientRequest {
  supplierGuid: string;
}

export interface SupplierInfo {
  guid: string;
  createdAt: Date;
  updatedAt: Date;
  websiteGuid: string;
  clientGuid: string;
  supplierWebsiteGuid: string;
  name: string;
  passClientPhone: boolean;
  passClientEmail: boolean;
  currency: string;
  serviceMappings: SupplierServiceMappingsInfo[];
}

export interface Coupon {
  isEnabled: boolean;
  code: string;
  startDate: Date;
  endDate: Date;
  appliestoFixedPrices: boolean;
  discountPercentage: number;
  discounValue: number;
  currency: string;
  pricePlan: string[];
  clients: string[];
}

export interface Client {
  firstName: string;
  lastName: string;
  phone: string | null;
  discountPercentage: number | null;
  useCompanyForInvoice: boolean;
  userId: number;
  clientId: string;
  isRemoved: boolean;
  salutation: string | null;
  currency: string | null;
  creditCardSetting: number;
  overwriteDriverAssigned: boolean;
  automaticallySendDriverInfoToCustomerByEmail: boolean;
  automaticallySendDriverInfoToCustomerBySms: boolean;
  overwriteDriverArrived: boolean;
  automaticallySendDriverArrivedEmail: boolean;
  automaticallySendDriverArrivedSms: boolean;
  showInstantPriceWhenBooking: boolean;
  hidePriceFromCustomer: boolean;
  companyName: string;
  streetAddress: any;
  additionalAddress: any;
  zipCode: any;
  city: any;
  country: any;
  costCenter: any;
  vatNumber: any;
}
export interface SupplierServiceMappingsInfo {
  providerServiceGuid: string;
  supplierServiceGuid: string;
}

export interface ClientInfo {
  firstName: string;
  lastName: string;
  email: string;
  userGuid: string;
  websiteGuid: string;
}

export interface DriverInfo {
  guid: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface SendSmsRequest {
  bookingGuid: string;
  toNumbers: string[];
  message: string;
}

export interface AssignDriverRequest {
  bookingGuid: string;
  driverGuid: string;
  payToDriver: number;
  carId: string;
  sendConfirmationEmailToDriver: boolean;
  sendConfirmationEmailToClient: boolean;
  sendConfirmationSmsToDriver: boolean;
}

export interface PerformanceInfo {
  currency: string;
  bookingsCount: number;
  totalEarnings: number;
  totalPayments: number;
  payments: { [key: string]: number };
  profit: number;
  losses: BookingProfitInfo[];
}

export interface BookingProfitInfo {
  customerId: string;
  currency: string;
  profit: number;
}

export interface SetBookingDriverStatusPayload {
  guid: string;
  driverAcceptanceStatus: DriverAcceptanceStatus;
  driverOnRouteStatus: DriverOnRouteStatus;
}

export enum DriverAcceptanceStatus {
  NotSet = 0,
  Pending = 1,
  Declined = 2,
  Accepted = 3,
}

export enum DriverOnRouteStatus {
  NotSet = 0,
  NotStarted = 1,
  OnMyWay = 2,
  WaitingForPassenger = 3,
  PassengerOnBoad = 4,
  DroppedPassenger = 5,
  PassengerNoShow = 6,
  MissedBooking = 7,
}

export interface BookingCancellationRequest {
  hash: string;
  reason: string;
  reasonDescription: string;
  sendConfirmationEmailToDriver: boolean;
  sendConfirmationSmsToDriver: boolean;
  sendConfirmationEmailToClient: boolean;
  clientReimbursementValue: number;
  driverPaymentValue: number;
}

// export interface SaveDriverRequest {
//    additionalInfo?: string;
//    additionalInfoDispatcher?: string;
//    primaryAddress: Address;
//    knownLanguages: Array<Language>;
//    dateOfBirth: string;
//    timezone: string;
//    nationality: string;
//    gender: string;
//    currency: string;
//    paymentMethod: string;
//    paymentPeriod: string;
//    websiteGuid: string;
//    guid: string;
//    userGuid: string;
//    email: string;
//    firstName: string;
//    lastName: string;
//    phoneNumber: string;
//    isHired: boolean;
//    driverImage?: ImageInfo
//    driverLicence?: DriverLicence;
//    driverPcoLicence?: Document;
//    driverInsurance?: Document;
//    driverPassport?: Document;
//    carInsurance?: Document;
//    isAdmin: boolean;
//    devices: Array<Device>;
//  }

interface ImageInfo {
  id: string;
  name: string;
  key: string;
  isTemporary: boolean;
  publicUrl: string;
  contentType: string;
  type: string;
}
interface Document {
  guid: string;
  documentNumber: string;
  expirationDate: string;
  image: ImageInfo;
  isMandatory: boolean;
}
interface DriverLicence {
  guid: string;
  documentNumber: string;
  expirationDate: string;
  frontImage: ImageInfo;
  backImage: ImageInfo;
  isMandatory: boolean;
}
interface Device {
  guid: string;
  deviceName?: string;
  deviceYearClass?: string;
  instalationId: string;
  osVersion: string;
  osName: string;
  appName: string;
  buildNumber: string;
  areNotificationsEnabled: boolean;
  isLocationEnabled: boolean;
  isLocationAlwaysEnabled: boolean;
  locationStatus: string;
  notificationsStatus: string;
}
interface Location {
  lat: number;
  lng: number;
}
interface Language {
  name: string;
  level: number;
}
interface Address {
  name: string;
  addressName: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  latLng: Location;
}

interface ImageInfo {
  id: string;
  publicUrl: string;
  type: string;
}
interface SaveVehicleRequest {
  id: string;
  createdAt: string;
  updatedAt: string;
  isRemoved: boolean;
  websiteGuid: string;
  carOwnerId: string;
  carOwnerType: number;
  make: string;
  model: string;
  year: number;
  color: string;
  plateNumber: string;
  images: ImageInfo[];
  documents: DocumentInfo[];
}
interface DocumentInfo extends ImageInfo {
  expiration: string;
}
interface SaveCarsRequest {
  id: string;
  createdAt: string;
  updatedAt: string;
  isRemoved: boolean;
  websiteGuid: string;
  carOwnerId: string;
  carOwnerType: number;
  make: string;
  model: string;
  year: number;
  color: string;
  plateNumber: string;
  images: ImageInfo[];
  documents: DocumentInfo[];
}

interface ImageInfo {
  id: string;
  publicUrl: string;
  type: string;
}

interface DocumentInfo {
  id: string;
  publicUrl: string;
  type: string;
  expiration: string;
}

export interface DriverLocation {
  id: string;
  guid: string;
  userGuid: string;
  websiteGuid: string;
  email: string;
  phone: string;
  whatsApp: string;
  activeBookingGuid: string;
  updatedAt: string;
  dbLocationUpdatedAt: string;
  firstName: string;
  lastName: string;
  fullName: string;
  lastLocation: LastLocation;
}

export interface LastLocation {
  timestamp: string;
  driverGuid: string;
  bookingGuid: string;
  driverAppVersion: string;
  accuracy: number;
  altitude: number;
  altitudeAccuracy: number;
  heading: number;
  speed: number;
  lat: number;
  lng: number;
  activeBookingGuid: string;
  activeBookingClientId: string;
  activeBookingPickupUtc: string;
  activeBookingTimezoneId: string;
  localPickupDateTime: string;
}
